import React, { createContext } from 'react';
import { initializeApp, getApp, getApps, setLogLevel } from "firebase/app";
import { getDatabase, ref, query, orderByChild, equalTo } from "firebase/database";
import { initializeAuth, getAuth, GoogleAuthProvider, OAuthProvider, signInWithPhoneNumber, PhoneAuthProvider, RecaptchaVerifier, unlink, updatePhoneNumber, linkWithPhoneNumber, indexedDBLocalPersistence, browserLocalPersistence, getReactNativePersistence } from "firebase/auth";
import { getStorage, ref as stRef } from "firebase/storage";
import { Platform } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage'; // Import AsyncStorage

const FirebaseContext = createContext(null);

let firebase = {
    app: null,
    database: null,
    auth: null,
    storage: null,
};

const createFullStructure = (app, db, auth, storage, config) => {
    if (!app || !db || !auth || !storage) {
        throw new Error("Firebase app, database, auth, or storage is not initialized.");
    }

    return {
        app,
        config,
        database: db,
        auth,
        storage,
        authRef: getAuth,
        googleProvider: new GoogleAuthProvider(),
        appleProvider: new OAuthProvider('apple.com'),
        phoneProvider: new PhoneAuthProvider(auth),
        RecaptchaVerifier: RecaptchaVerifier,
        signInWithPhoneNumber: signInWithPhoneNumber,
        updatePhoneNumber: updatePhoneNumber,
        unlink: unlink,
        googleCredential: (idToken, accessToken) => GoogleAuthProvider.credential(idToken, accessToken),
        linkWithPhoneNumber: linkWithPhoneNumber,
        mobileAuthCredential: (verificationId, code) => PhoneAuthProvider.credential(verificationId, code),
        usersRef: db ? ref(db, 'users') : null,
        bookingRef: db ? ref(db, 'bookings') : null,
        cancelreasonRef: db ? ref(db, 'cancel_reason') : null,
        settingsRef: db ? ref(db, 'settings') : null,
        smtpRef: db ? ref(db, "smtpdata") : null,
        carTypesRef: db ? ref(db, 'cartypes') : null,
        carTypesEditRef: (id) => db ? ref(db, "cartypes/" + id) : null,
        carDocImage: (id) => storage ? stRef(storage, `cartypes/${id}`) : null,
        promoRef: db ? ref(db, 'promos') : null,
        promoEditRef: (id) => db ? ref(db, "promos/" + id) : null,
        notifyRef: db ? ref(db, "notifications/") : null,
        notifyEditRef: (id) => db ? ref(db, "notifications/" + id) : null,
        addressRef: (uid, id) => db ? ref(db, "savedAddresses/" + uid + "/" + id) : null,
        addressEditRef: (uid) => db ? ref(db, "savedAddresses/" + uid) : null,
        singleUserRef: (uid) => db ? ref(db, "users/" + uid) : null,
        profileImageRef: (uid) => storage ? stRef(storage, `users/${uid}/profileImage`) : null,
        verifyIdImageRef: (uid) => storage ? stRef(storage, `users/${uid}/verifyIdImage`) : null,
        verifyIdImageSelfieRef: (uid) => storage ? stRef(storage, `users/${uid}/verifyIdImageSelfie`) : null,
        bookingImageRef: (bookingId, imageType) => storage ? stRef(storage, `bookings/${bookingId}/${imageType}`) : null,
        driversRef: db ? query(ref(db, "users"), orderByChild("usertype"), equalTo("driver")) : null,
        driverDocsRef: (uid) => storage ? stRef(storage, `users/${uid}/license`) : null,
        driverDocsRefBack: (uid) => storage ? stRef(storage, `users/${uid}/licenseBack`) : null,
        singleBookingRef: (bookingKey) => db ? ref(db, "bookings/" + bookingKey) : null,
        requestedDriversRef: (bookingKey) => db ? ref(db, "bookings/" + bookingKey + "/requestedDrivers") : null,
        referralIdRef: (referralId) => db ? query(ref(db, "users"), orderByChild("referralId"), equalTo(referralId)) : null,
        trackingRef: (bookingId) => db ? ref(db, 'tracking/' + bookingId) : null,
        tasksRef: () => db ? query(ref(db, 'bookings'), orderByChild('status'), equalTo('NEW')) : null,
        singleTaskRef: (uid, bookingId) => db ? ref(db, "bookings/" + bookingId + "/requestedDrivers/" + uid) : null,
        bookingListRef: (uid, role) =>
            db ? (role === 'customer' ? query(ref(db, 'bookings'), orderByChild('customer'), equalTo(uid)) :
                (role === 'driver' ? query(ref(db, 'bookings'), orderByChild('driver'), equalTo(uid)) :
                    (role === 'fleetadmin' ? query(ref(db, 'bookings'), orderByChild('fleetadmin'), equalTo(uid)) : ref(db, 'bookings')))) : null,
        chatRef: (bookingId) => db ? ref(db, 'chats/' + bookingId + '/messages') : null,
        withdrawRef: db ? ref(db, 'withdraws/') : null,
        languagesRef: db ? ref(db, "languages") : null,
        languagesEditRef: (id) => db ? ref(db, "languages/" + id) : null,
        walletHistoryRef: (uid) => db ? ref(db, "walletHistory/" + uid) : null,
        userNotificationsRef: (uid) => db ? ref(db, "userNotifications/" + uid) : null,
        userRatingsRef: (uid) => db ? ref(db, "userRatings/" + uid) : null,
        carsRef: (uid, role) => db ? (role === 'driver' ? query(ref(db, 'cars'), orderByChild('driver'), equalTo(uid)) :
            (role === 'fleetadmin' ? query(ref(db, 'cars'), orderByChild('fleetadmin'), equalTo(uid)) : ref(db, 'cars'))) : null,
        carAddRef: db ? ref(db, "cars/") : null,
        carEditRef: (id) => db ? ref(db, "cars/" + id) : null,
        panicAddRef: db ? ref(db, "panics/") : null,
        panicEditRef: (id) => db ? ref(db, "panics/" + id) : null,
        carImage: (id, index) => storage ? stRef(storage, `cars/${id}/${index}`) : null,
        allLocationsRef: db ? ref(db, "locations") : null,
        userLocationRef: (uid) => db ? ref(db, "locations/" + uid) : null,
    };
};

const FirebaseProvider = ({ config, children }) => {
    let app, auth, database, storage;

    try {
        if (!getApps().length) {
            app = initializeApp(config);
            setLogLevel('debug');

            let persistence;

            if (Platform.OS === "web") {
                persistence = browserLocalPersistence;
              } else {
                // Importação dinâmica para evitar erro na web
                const { getReactNativePersistence } = require("firebase/auth");
                persistence = getReactNativePersistence(AsyncStorage);
              }     

            auth = initializeAuth(app, {
                persistence: persistence,
            });

            database = getDatabase(app);
            storage = getStorage(app);
        } else {
            app = getApp();
            auth = getAuth(app);
            database = getDatabase(app);
            storage = getStorage(app);
        }

        if (!app || !database || !auth || !storage) {
            throw new Error("Firebase services are not initialized properly.");
        }

        firebase = createFullStructure(app, database, auth, storage, config);
    } catch (error) {
        console.error("Error initializing Firebase:", error);
        return null;
    }

    return (
        <FirebaseContext.Provider value={firebase}>
            {children}
        </FirebaseContext.Provider>
    );
};

export {
    firebase,
    FirebaseProvider,
    FirebaseContext,
};