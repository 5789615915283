import {
  FETCH_TASKS,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILED,
  ACCEPT_TASK,
  CANCEL_TASK,
} from "../store/types";
import store from "../store/store";
import { updateProfile } from "./authactions";
import { RequestPushMsg } from "../other/NotificationFunctions";
import { firebase } from '../config/configureFirebase';
import { onValue, runTransaction, get, off, push } from "firebase/database";
import { driverQueue } from '../other/sharedFunctions';

const waitForTypeRefs = async () => {
  while (!firebase.tasksRef && !firebase.auth) {
    await new Promise((resolve) => setTimeout(resolve, 10)); // Aguarda 10ms
  }
  return {
    tasksRef: firebase.tasksRef,
    auth: firebase.auth
  };
};

export const fetchTasks = () => async (dispatch) => {
  const { tasksRef, auth } = await waitForTypeRefs();
  
  const uid = auth.currentUser.uid;
  
  off(tasksRef());
  dispatch({
    type: FETCH_TASKS,
    payload: null,
  });
  onValue(tasksRef(), (snapshot) => {
    if (snapshot.val()) {
      let data = snapshot.val();
      const arr = Object.keys(data)
        .filter(
          (i) => data[i].requestedDrivers && data[i].requestedDrivers[uid]
        )
        .map((i) => {
          data[i].id = i;
          return data[i];
        });
      dispatch({
        type: FETCH_TASKS_SUCCESS,
        payload: arr,
      });
    } else {
      dispatch({
        type: FETCH_TASKS_FAILED,
        payload: store.getState().languagedata.defaultLanguage.no_tasks,
      });
    }
  });
};

export const acceptTask = (task) => (dispatch) => {
  const { auth, trackingRef, singleUserRef, singleBookingRef } = firebase;

  const uid = auth.currentUser.uid;
  
  onValue(singleUserRef(uid), (snapshot) => {
    if (!snapshot.exists()) {
      console.error("Dados do perfil do usuário não encontrados.");
      return;
    }
    let profile = snapshot.val();

    runTransaction(singleBookingRef(task.id), (booking) => {
      if (booking && booking.requestedDrivers) {
        booking.driver = uid;
        booking.driver_image = profile.profile_image ? profile.profile_image : "";
        booking.car_image = profile.car_image ? profile.car_image : "";
        booking.driver_name = profile.firstName + " " + profile.lastName;
        booking.driver_contact = profile.mobile;
        booking.driver_token = profile.pushToken ? profile.pushToken : '';
        booking.vehicle_number = profile.vehicleNumber ? profile.vehicleNumber : "";
        booking.vehicle_model = profile.vehicleModel ? profile.vehicleModel : "";
        booking.vehicle_year = profile.vehicleYear ? profile.vehicleYear : "";
        booking.other_info = profile.other_info ? profile.other_info : "";
        booking.driverRating = profile.rating ? profile.rating : "0";
        booking.fleetadmin = profile.fleetadmin ? profile.fleetadmin : "";
        booking.status = "ACCEPTED";
        booking.driverDeviceId = task.driverDeviceId ? task.driverDeviceId : null;
        booking.requestedDrivers = null;
        booking.driverEstimates = null;
        booking.driver_pix = profile.pix ? profile.pix : "não cadastrado";
        booking.accept_task = new Date().getTime();
        booking.driver_cpf = profile.verifyId ? profile.verifyId : "";
        // DEVE ACRESCENTAR ALGO AQUI PARA QUE SEJA ADICIONADO NA COLLECTION BOOKING
        return booking;
      }
    })
      .then(() => {
        get(singleBookingRef(task.id))
          .then((snapshot) => {
            if (!snapshot.exists()) {
              return;
            } else {
              let requestedDrivers =
                snapshot.val() && snapshot.val().requestedDrivers;
              let driverId = snapshot.val() && snapshot.val().driver;

              if (requestedDrivers == undefined && driverId === uid) {
                updateProfile({ queue: driverQueue ? false : true })(dispatch);
                RequestPushMsg(task.customer_token, {
                  title:
                    store.getState().languagedata.defaultLanguage
                      .notification_title,
                  msg:
                    profile.firstName +
                    store.getState().languagedata.defaultLanguage
                      .accept_booking_request,
                  screen: "BookedCab",
                  params: { bookingId: task.id },
                });

                const driverLocation = store.getState().gpsdata.location;

                push(trackingRef(task.id), {
                  at: new Date().getTime(),
                  status: "ACCEPTED",
                  lat: driverLocation.lat,
                  lng: driverLocation.lng,
                });

                dispatch({
                  type: ACCEPT_TASK,
                  payload: { task: task },
                });
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      });
  }, { onlyOnce: true });
};

export const cancelTask = (bookingId) => async (dispatch) => {
  console.log("(cancelTask) Cancelando tarefa: ", bookingId);

  const { auth, singleBookingRef } = firebase;

  console.log("(cancelTask) auth: ", auth, "singleBookingRef: ", singleBookingRef);

  const uid = auth.currentUser?.uid;
  console.log("(cancelTask) uid: ", uid);

  if (!uid) {
    console.error("(cancelTask) Usuário não está logado. Cancelando operação.");
    return;
  }

  try {
    await runTransaction(singleBookingRef(bookingId), (booking) => {
      if (booking && booking.requestedDrivers) {
        console.log("(cancelTask) if (booking && booking.requestedDrivers): ", booking.requestedDrivers);

        if (booking.requestedDrivers !== null && Object.keys(booking.requestedDrivers).length === 1) {
          console.log("(cancelTask) if (booking.requestedDrivers !== null && Object.keys(booking.requestedDrivers).length === 1)");
          
          booking.status = "NEW";
          booking.requestedDrivers = null;
          booking.driverEstimates = null;

          console.log("(cancelTask) Alterando status da reserva para 'NEW' e limpando requestedDrivers e driverEstimates");

          RequestPushMsg(booking.customer_token, {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage.booking_cancelled + bookingId,
            screen: "BookedCab",
            params: { bookingId: bookingId },
          });

          console.log("(cancelTask) Enviando notificação para o cliente sobre o cancelamento.");

          dispatch({
            type: CANCEL_TASK,
            payload: { uid, bookingId },
          });

          console.log("(cancelTask) Dispatching action CANCEL_TASK para Redux.");

        } else {
          console.log("(cancelTask) Deletando booking.requestedDrivers[uid]: ", booking.requestedDrivers[uid]);
          delete booking.requestedDrivers[uid];
        }

        if (booking.driverOffers && booking.driverOffers[uid]) {
          console.log("(cancelTask) Deletando booking.driverOffers[uid]: ", booking.driverOffers[uid]);
          delete booking.driverOffers[uid];
        }

        if (booking.selectedBid && booking.selectedBid.driver === uid) {
          console.log("(cancelTask) Deletando booking.selectedBid pois o driver corresponde ao uid.");
          delete booking.selectedBid;
        }

        return booking; // Retornando o objeto atualizado para garantir a transação do Firebase
      } else {
        console.log("(cancelTask) booking ou booking.requestedDrivers não existe, sem alterações.");
      }

      return booking;
    });
  } catch (error) {
    console.error("(cancelTask) Erro ao cancelar a tarefa:", error);
  }
};
